import { useLayoutEffect } from "react"

import { useFetchCallAssistEventData } from "src/data/callAssist/queries/callAssistQueries"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"

export function IncidentDetails({
  eventId,
  onError,
}: {
  eventId: string
  onError: () => void
}) {
  const fetchCallAssistEventData = useFetchCallAssistEventData({
    eventId,
    options: { enabled: !!eventId },
  })
  const hasFetchError =
    fetchCallAssistEventData.error && !fetchCallAssistEventData.isInitialLoading

  useLayoutEffect(() => {
    if (hasFetchError) {
      onError()
    }
  })

  if (hasFetchError) {
    return <MBanner type="error">Event is missing or has expired</MBanner>
  }

  if (
    fetchCallAssistEventData.isInitialLoading &&
    !fetchCallAssistEventData.isError
  ) {
    return <MCircularProgress />
  }

  if (!fetchCallAssistEventData.data) {
    return null
  }

  return (
    <div>
      <div>
        <strong>Property name: </strong>
        {fetchCallAssistEventData.data?.home_name}
      </div>

      <div>
        <strong>Event UTC Timestamp: </strong>
        {fetchCallAssistEventData.data.event.timestamp}
      </div>
    </div>
  )
}
