import { useState } from "react"
import styled from "styled-components"

import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MTextField } from "src/ui/MTextField/MTextField"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

export function EventIdForm({
  eventIdInitial,
  onEventIdChange,
  disabled,
  onSubmit,
  hideSubmit,
}: {
  eventIdInitial: string
  onEventIdChange: (s: string) => void
  disabled: boolean
  onSubmit: (id: string) => void
  hideSubmit: boolean
}) {
  const [eventIdInput, setEventIdInput] = useState(eventIdInitial)
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(eventIdInput)
      }}
    >
      <MTextField
        label={"Event Id"}
        disabled={disabled}
        value={eventIdInput}
        onChange={(value) => {
          onEventIdChange(value)
          setEventIdInput(value)
        }}
      />

      <Show if={!hideSubmit}>
        <MButtonLegacy
          size="large"
          type="submit"
          disabled={disabled || !eventIdInput}
        >
          Show event
        </MButtonLegacy>
      </Show>
    </Form>
  )
}

const Form = styled.form`
  display: grid;
  gap: ${spacing.M};
`
