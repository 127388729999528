import { useState } from "react"
import styled from "styled-components"

import { CallAssistReportForm } from "src/components/CallAssistFeedback/CallAssistReportForm"
import { EventIdForm } from "src/components/CallAssistFeedback/EventIdForm"
import { IncidentDetails } from "src/components/CallAssistFeedback/IncidentDetails"
import { ImageBackdrop } from "src/components/ImageBackdrop/ImageBackdrop"
import backdropImage from "src/ui/images/photo-call-assist.jpg"
import backdropImagePortrait from "src/ui/images/photo-call-assist-portrait.jpg"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"
import { Optional } from "src/utils/tsUtil"

export function CallAssistFeedback({
  eventIdParam,
}: {
  eventIdParam: Optional<string>
}) {
  const hasEventIdFromParam = !!eventIdParam
  const [submittedEventId, setSubmittedEventId] = useState(eventIdParam ?? "")
  const [hasSentFeedback, setHasSentFeedback] = useState(false)
  const [incidentDetailError, setIncidentDetailError] = useState(false)

  function reset() {
    setSubmittedEventId("")
    setIncidentDetailError(false)
  }

  if (hasSentFeedback) {
    return (
      <ImageBackdrop
        landscapeImageUrl={backdropImage}
        portraitImageUrl={backdropImagePortrait}
      >
        <StyledCard>
          <MText variant="heading1">Call Assist Feedback</MText>
          <MText variant="subtitle">
            Thank you for the feedback you can now close the page.
          </MText>
        </StyledCard>
      </ImageBackdrop>
    )
  }

  return (
    <ImageBackdrop
      landscapeImageUrl={backdropImage}
      portraitImageUrl={backdropImagePortrait}
    >
      <StyledCard>
        <MText variant="heading1">Call Assist Feedback</MText>

        <MText variant="heading3">Event Id</MText>
        <EventIdForm
          disabled={hasEventIdFromParam}
          hideSubmit={hasEventIdFromParam || !!submittedEventId}
          eventIdInitial={eventIdParam ?? ""}
          onEventIdChange={(id) => {
            if (id !== submittedEventId) {
              reset() // reset feedback form as soon as event id changes
            }
          }}
          onSubmit={setSubmittedEventId}
        />

        <Show if={!!submittedEventId}>
          <MText variant="heading3">Event</MText>
          <IncidentDetails
            eventId={submittedEventId}
            onError={() => setIncidentDetailError(true)}
          />
        </Show>

        <Show if={!!submittedEventId && !incidentDetailError}>
          <MText variant="heading3">How did the call go?</MText>
          <CallAssistReportForm
            onSubmitSuccess={() => setHasSentFeedback(true)}
            eventId={submittedEventId}
            submitDisabled={incidentDetailError}
          />
        </Show>
      </StyledCard>
    </ImageBackdrop>
  )
}

const StyledCard = styled(MCard)`
  padding: ${spacing.XL2};
  display: flex;
  gap: ${spacing.M};
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
`
